<template>
	<div
		style="display: flex; flex-direction: row; justify-content: center"
		class="roundup-settings-embed"
	>
		<div class="pr-7">
			<h1 style="font-size: 1.5rem" slot="title">RoundUp Type</h1>
			<p>
				Select which RoundUp type customers will see when they choose to
				RoundUp at Checkout.
			</p>
			<form v-on:submit.prevent="onSubmit">
				<RadioInput
					type="radio"
					text="RoundUp purchases to the next dollar amount."
					label="default"
					name="dollarButton"
					v-model="picked"
				/>
				<RadioInput
					type="radio"
					text="RoundUp a fixed amount."
					label="dollar"
					name="defaultButton"
					v-model="picked"
				/>
				<MandatoryToggle
					v-show="picked === 'dollar'"
					:options="options"
					prefix="$"
					:gutters="true"
					:bigText="true"
					@change="handleChange"
					:value="getIndex"
					style="margin-top: 16px !important; margin-bottom: 16px !important;"
					:notMandatory="true"
					:deep="false"
				/>
				<div
					style="border-radius: 4px !important; display: flex; flex-direction: row; justify-content: center; width: 100%"
				>
					<ui-button
						style="border-radius: 4px !important; font-size: 16px !important"
						native-type="submit"
						:loading="isSaving"
						:disabled="!edited"
						>Save</ui-button
					>
				</div>
			</form>
		</div>
		<v-divider vertical></v-divider>
		<div class="px-7">
			<h1 style="font-size: 1.5rem">
				Checkout Preview
			</h1>
			<p>
				Your RoundUp at Checkout may vary slightly based on your
				selected integration.
			</p>
			<div id="embed">
				<Embed :type="picked" :fixedAmount="amount" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Screen from '../../../ui/Screen'
import TabbedScreen from '../../../ui/TabbedScreen'
import Modal from '../../../ui/Modal'
import UiLabel from '../../../ui/Label'
import RadioInput from '../../../ui/RadioInput'
import UiButton from '../../../ui/Button'
import UiToggle from '../../../ui/Toggle'
import Embed from '../../../ui/Embed'
import MandatoryToggle from '../../../ui/MandatoryToggle.vue'

export default {
	name: 'roundup-settings',
	components: {
		Screen,
		RadioInput,
		UiLabel,
		UiButton,
		TabbedScreen,
		Modal,
		UiToggle,
		Embed,
		MandatoryToggle
	},
	data() {
		return {
			isSaving: false,
			picked: 'default',
			options: [1, 5, 10, 20],
			amount: null,
			originalAmount: null,
			edited: false
		}
	},
	computed: {
		...mapGetters(['merchant']),
		getIndex() {
			const idx = this.options.indexOf(this.amount)
			if (idx > -1) return idx
			return null
		}
	},
	methods: {
		...mapActions(['updateEmbedAmount', 'getEmbed']),
		handleChange(i) {
			this.amount = this.options[i]
		},

		onSubmit() {
			this.isSaving = true
			let options = {}

			if (this.picked === 'dollar') {
				options.fixed_amount = this.amount * 100
				options.use_fixed_amount = true
			}

			this.updateEmbedAmount(options)
				.then(() => {
					this.edited = false
					this.getEmbed()
					this.isSaving = false
					var choice = `give $${this.amount}`
					if (this.picked === 'default') {
						choice = 'RoundUp to the next dollar'
					}
					this.$swal({
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 3000,
						title: 'Success',
						text: `You are now asking customers to ${choice}.`
					})
				})
				.catch(err => {
					this.isSaving = false
					this.$swal('Error', err.response.data.message)
				})
		}
	},
	watch: {
		amount(v) {
			if (v !== this.merchant.fixed_amount / 100) {
				this.edited = true
			} else {
				this.edited = false
			}
		},
		picked(v) {
			if (v === 'dollar' && !this.amount) {
				this.amount = 1
			}
			if (
				v === 'dollar' &&
				!(
					this.merchant.dollar_ru ||
					(this.merchant.use_fixed_amount &&
						this.amount === this.merchant.fixed_amount / 100)
				)
			) {
				this.edited = true
				return
			}

			if (
				v === 'dollar' &&
				(this.merchant.dollar_ru ||
					(this.merchant.use_fixed_amount &&
						this.amount === this.merchant.fixed_amount / 100))
			) {
				this.edited = false
				return
			}

			if (
				v === 'default' &&
				(this.merchant.dollar_ru || this.merchant.use_fixed_amount)
			) {
				this.edited = true
				return
			}

			if (
				v === 'default' &&
				!(this.merchant.dollar_ru || this.merchant.use_fixed_amount)
			) {
				this.edited = false
				return
			}
		},
		edited(v) {
			this.$emit('edited', v)
		}
	},
	mounted() {
		this.picked =
			this.merchant.dollar_ru || this.merchant.use_fixed_amount
				? 'dollar'
				: 'default'

		if (this.merchant.use_fixed_amount) {
			this.amount = parseInt(this.merchant.fixed_amount) / 100
			this.originalAmount = parseInt(this.merchant.fixed_amount) / 100
		} else if (this.merchant.dollar_ru) {
			this.amount = 1
			this.originalAmount = 1
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@roundupapp/component-library/src/styles/index';

.roundup-settings-embed::v-deep .roundup-button-m:hover:enabled::after {
	border-radius: 4px;
}

.roundup-settings-embed::v-deep .roundup-button-m:disabled {
	background: #f5f6f8;
	color: #747a8e !important;
	border: 1px solid #e7e7e8;
}
</style>
