<template>
	<card
		class="embed"
		v-if="embed.institution.name"
		style="padding-top: 0px; margin-left: 0px"
	>
		<div class="roundup-row">
			<h1 style="font-size: 1.5rem" v-if="type === 'default'">
				RoundUp for Charity
			</h1>
			<h1 v-else>Add ${{ fixedAmount }} for Charity</h1>
		</div>
		<div class="roundup-row content">
			<div class="toggle">
				<ui-toggle />
			</div>
			<div>
				<div
					class="multiplier"
					v-if="
						embed.merchant.multiplier &&
							!embed.merchant.match_paused
					"
				>
					We are matching your donations up to
					{{ embed.merchant.multiplier * 100 }}%!
				</div>
				<p style="margin-bottom: 10px" v-if="type === 'default'">
					I would like to RoundUp my purchase to $27.00 and give $0.56
					to
					{{ embed.institution.name }}, helping them
					{{ embed.institution.full_impact_statement }}.
				</p>
				<p style="margin-bottom: 10px" v-else>
					I would like to add {{ fixedAmountLong }} to my purchase and
					give that {{ fixedAmountLong }} to
					{{ embed.institution.name }}, helping them
					{{ embed.institution.full_impact_statement }}.
				</p>
			</div>
		</div>
	</card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UiButton from './Button'
import UiToggle from './Toggle'
import Card from './Card'
import { dollarInputFormat, numberFormat } from '../../utilities'

export default {
	name: 'roundup_at_checkout',
	components: { UiButton, UiToggle, Card },
	props: ['type', 'fixedAmount'],
	computed: {
		...mapGetters(['embed']),
		fixedAmountLong() {
			if (this.fixedAmount) {
				return numberFormat(this.fixedAmount)
			}
			return null
		}
	},
	methods: {
		...mapActions(['getEmbed'])
	},
	mounted() {
		this.getEmbed()
	}
}
</script>

<style lang="scss" scoped>
@import '@roundupapp/component-library/src/styles/index';
.embed {
	max-width: 600px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-bottom: 20px;
	h1 {
		margin-bottom: 0px;
		margin-top: 0px;
		font-size: 1.5rem;
	}
	.roundup-row {
		display: flex !important;
		flex-direction: row !important;
		justify-content: flex-start !important;
		&.content {
			background: $roundup-lightest-grey;
			border-radius: 5px;
			padding: 20px;
			border: 1px solid #d3deed;
			margin: 10px 0 20px 0;
		}
	}
	.toggle {
		justify-content: center;
		display: flex;
		align-items: center;
	}
	.multiplier {
		background-color: #333;
		color: #fff;
		margin-left: 10px;
		margin-bottom: 10px;
		padding: 4px 10px;
		border-radius: 3px;
	}
	.fineprint {
		font-size: 11px;
		color: #4a4a4a;
	}
}
</style>
