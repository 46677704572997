var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticStyle:{"margin":"16px 0px 16px 0px !important","align-items":"center","justify-content":"center","width":"100% !important"}},[_c('v-btn-toggle',{staticStyle:{"width":"100% !important","diplay":"flex"},attrs:{"value":_vm.value,"mandatory":true && !_vm.notMandatory},on:{"change":function (v) { return _vm.$emit('change', v); }}},_vm._l((_vm.options),function(option,i){return _c('v-btn',{key:i,class:[
				'option',
				_vm.gutters && 'gutters',
				_vm.bigText && 'bigText',
				_vm.deep && 'deep',
				_vm.disabled && 'disabled'
			],style:({
				border: ("1px solid " + (_vm.disabled ? '#747a8d' : '#E3E3E3') + " !important")
			}),attrs:{"disabled":_vm.disabled}},[_vm._v(" "+_vm._s(_vm.buttonText(option))+" ")])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }