<template>
	<div class="screen-wrapper profile-view">
		<tabbed-screen
			class="profile-information"
			size="large"
			:tabs="[
				'General Information',
				'Team',
				'Integrations',
				'Donation Source',
				'RoundUps'
			]"
		>
			<div slot="0">
				<h1 slot="title" style="font-size: 1.5rem">Information</h1>
				<form slot="body" v-on:submit.prevent="onSubmit">
					<div class="roundup-row">
						<div class="col-12">
							<ui-label>
								<ui-input
									v-model="merchant.name"
									:disabled="true"
									placeholder="Organization Name"
								/>
							</ui-label>
						</div>
						<div class="col-12">
							<ui-label>
								<ui-input
									v-model="merchant.url"
									placeholder="www.roundupapp.com"
									:maxlength="100"
								/>
							</ui-label>
						</div>
					</div>

					<ui-label class="center">
						<ui-button native-type="submit" :loading="isSaving"
							>Update</ui-button
						>
					</ui-label>
				</form>

				<ui-label
					v-bind:style="{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						marginTop: '25px'
					}"
					v-if="role === 0"
				>
					<ui-toggle
						:style="{ marginRight: '10px' }"
						@input="activateDemo"
						:checked="demo === 'true'"
					>
					</ui-toggle>
					<h3 :style="{ marginTop: 0 }">Activate Demo Account</h3>
				</ui-label>
			</div>
			<div slot="1">
				<users />
			</div>
			<div slot="2">
				<div class="integration_card">
					<h1 style="font-size: 1.5rem">Big Commerce</h1>
					<div class="flex-row">
						<img
							src="@/assets/Big-Commerce_logo.jpg"
							style="height: 100px; width: 100px"
						/>
						<p style="flex: 5; margin-left: 20px">
							BigCommerce’s mission is to help merchants sell more
							at every stage of business growth. Simply put, we
							are: built for growth so merchants can realize their
							visions without compromise; designed for powerful
							performance to enable businesses to scale seamlessly
							with technology they can trust; efficient in both
							time and cost. To learn more, visit
							<a
								target="_blank"
								href="https://www.bigcommerce.com/"
								>bigcommerce.com.</a
							>
						</p>
						<div
							style="
								flex: 1;
								display: flex;
								justify-content: center;
								align-items: center;
								flex-direction: column;
							"
						>
							<ui-toggle
								:checked="merchant.embed_id"
								@input="toggleBCIntegration()"
							></ui-toggle>
							<router-link
								style="margin-top: 10px"
								:to="{ name: 'integrations.bigcommerce' }"
								>Relink BigCommerce</router-link
							>
						</div>
					</div>
				</div>
			</div>
			<div slot="3">
				<h1 style="font-size: 1.5rem" slot="title">Donation Source</h1>
				<div slot="body">
					<div v-if="!merchant.institution">
						<p class="section-header">No cause selected.</p>
						<p>
							We need you to select your cause to continue the
							setup process.
						</p>
						<router-link :to="{ name: 'select-cause' }">
							<ui-button>Add cause</ui-button>
						</router-link>
					</div>
					<div
						v-if="merchant.institution && !merchant.payment_last_4"
					>
						<p class="section-header">No donation source added.</p>
						<p>
							We need to access your bank account to send RoundUps
							to the cause you’ve selected.
						</p>
						<!-- This is a bit different than the mocks, but the route linked has all of the interactions necessary to move forward and feels like a good source of truth to reduce redundancy -->
						<router-link :to="{ name: 'select-bank' }">
							<ui-button>Add donation source</ui-button>
						</router-link>
					</div>
					<donation-source-connection v-if="merchant.payment_last_4">
						<div slot="name">x{{ merchant.payment_last_4 }}</div>
						<div slot="bank">{{ merchant.payment_name }}</div>
						<div slot="action">
							<ui-button
								@click="removeDonationSource"
								class="danger"
								:style="{ marginTop: 0 }"
								>Remove</ui-button
							>
						</div>
					</donation-source-connection>
					<modal
						v-model="showDonationSourceRemovalModal"
						size="small"
					>
						<h1>Remove donation source?</h1>
						<p>
							Donations to your cause will be on pause until you
							connect another donation source.
						</p>
						<div class="modal-actions">
							<ui-button
								class="danger"
								@click="handleDeleteLink"
								:loading="isRemovingDonationSource"
								>Remove</ui-button
							>
							<ui-button @click="handleCancelRemoveDonationSource"
								>Cancel</ui-button
							>
						</div>
					</modal>
					<div
						v-if="
							merchant.payment_name && !merchant.payment_verified
						"
					>
						<p class="section-header">Authentication in progress</p>
						<p>
							We’ve deposited 2 micro deposits into the bank
							account you provided. When they’ve been posted
							(within 2-3 business days), you can verify them
							using the option below.
						</p>
						<ui-button @click="verifyLink"
							>Verify Deposits</ui-button
						>
					</div>
				</div>
			</div>
			<div slot="4">
				<RoundUpSettings @edited="onEdit" />
			</div>
		</tabbed-screen>
		<Confirm ref="confirm" />
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { get } from '../../../utilities'

import Screen from '../../ui/Screen'
import TabbedScreen from '../../ui/TabbedScreen'
import Modal from '../../ui/Modal'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import UiButton from '../../ui/Button'
import UiToggle from '../../ui/Toggle'
import Users from '../Users/UserListView'
import TokenList from '../../layout/TokenList'
import DonationSourceConnection from '../../ui/donation-source/Connection'
import RoundUpSettings from './tabs/RoundUpSettings'
import Confirm from '../../ui/Confirm.vue'

import SmsTemplates from './SMSTemplates'

export default {
	name: 'profile-view',
	components: {
		Screen,
		UiInput,
		UiLabel,
		UiButton,
		TabbedScreen,
		Users,
		Modal,
		TokenList,
		UiToggle,
		SmsTemplates,
		DonationSourceConnection,
		RoundUpSettings,
		Confirm
	},
	data() {
		return {
			isSaving: false,
			isRemovingDonationSource: false,
			merchant: cloneDeep(this.$store.state.merchant.item),
			demo: localStorage.getItem('demo') || false,
			showDonationSourceRemovalModal: false,
			edited: false
		}
	},
	title: {
		inner: 'Settings'
	},
	computed: {
		impact_statement() {
			return get(this.merchant, 'impact_statement', '________________')
		},
		full_impact_statement() {
			return get(
				this.merchant,
				'full_impact_statement',
				'________________'
			)
		},
		...mapState({
			isLoading: ({ notify }) => notify.isFetching,
			me: ({ me }) => me.current
		}),
		...mapGetters(['role'])
	},
	methods: {
		...mapActions([
			'getTemplates',
			'update',
			'updateTemplates',
			'sendSMS',
			'deleteLink',
			'getPublicToken',
			'refreshLink',
			'getMerchantDetails'
		]),
		onEdit(v) {
			this.edited = v
		},
		removeDonationSource() {
			this.showDonationSourceRemovalModal = true
		},
		handleCancelRemoveDonationSource() {
			this.showDonationSourceRemovalModal = false
		},
		verifyLink() {
			this.getPublicToken()
				.then(data => {
					const handler = window.Plaid.create({
						clientName: this.clientName,
						env: this.env,
						key: this.publicKey,
						onSuccess: res => {
							this.refreshLink({ token: res }).then(() => {
								this.$swal({
									title: 'Success',
									text:
										"You're all setup! Thanks for helping us turn pocket change into global change!",
									showCancelButton: false,
									allowOutsideClick: false,
									allowEscapeKey: false
								})
							})
						},
						onError: err => {
							console.log(err)
						},
						product: 'auth',
						token: data.public_token,
						webhook: this.webhook
					})
					handler.open()
				})
				.catch(err => {
					console.log(err)
					this.$swal(
						'Error',
						'It looks like we are unable to relink your bank at this time. Please contact support.'
					)
				})
		},
		onSubmit() {
			this.isSaving = true
			this.update(this.merchant)
				.then(() => {
					this.isSaving = false
					this.$swal(
						'Success',
						'Your information has successfully been updated.'
					)
				})
				.catch(({ response }) => {
					this.isSaving = false
					this.$swal('Error', response.data.message)
				})

			return false
		},
		onUpdateTemplates() {
			this.isSaving = true
			const templates = [this.thankTemplate, this.reminderTemplate]
			this.updateTemplates(templates)
				.then(() => {
					this.isSaving = false
					this.$swal(
						'Success',
						'Your templates has successfully been updated.'
					)
				})
				.catch(({ response }) => {
					this.isSaving = false
					this.$swal('Error', response.data.message)
				})
		},
		activateDemo() {
			this.demo = !this.demo
			localStorage.demo = this.demo
			if (localStorage.demo) {
				console.log('Demo activated: ', localStorage.demo)
			}
		},
		toggleBCIntegration() {
			if (this.merchant.embed_id) {
				this.$swal(
					'Sorry to See You Go',
					'Please visit your BigCommerce store to finish uninstalling our app.'
				)
				this.merchant.embed_id = null
			} else {
				window.location.href =
					'https://www.bigcommerce.com/apps/roundup'
			}
		},
		handleDeleteLink() {
			this.isRemovingDonationSource = true

			this.deleteLink()
				.then(() => {
					this.getMerchantDetails().then(
						data => (this.merchant = cloneDeep(data))
					)
					this.handleCancelRemoveDonationSource()
					this.isRemovingDonationSource = false

					this.$swal({
						title: 'Success',
						text: 'Your bank has been deleted',
						showCancelButton: false,
						allowOutsideClick: false,
						allowEscapeKey: false
					})
				})
				.catch(err => {
					this.isRemovingDonationSource = false

					console.log(err)

					this.$swal(
						'Error',
						'It looks like we are unable to delete your bank at this time. Please contact support.'
					)
				})
		}
	},
	mounted() {
		if (!localStorage.demo) {
			localStorage.setItem('demo', false)
		}
	},
	async beforeRouteLeave(to, from, next) {
		if (this.edited) {
			;(await this.$refs.confirm.open('You have unsaved changes'))
				? null
				: next()
		} else {
			next()
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.profile-view {
	.col-12 {
		padding: 0px;
	}
	.image-upload {
		position: relative;
		.image-upload-buttons {
			padding: 10px 0px;
			.upload-btn-wrapper {
				position: relative;
				overflow: hidden;
				display: inline-block;
				.btn {
					border: 1px solid $roundup-light-grey;
					color: $roundup-primary;
					background-color: #fff;
					padding: 8px 20px;
					border-radius: 8px;
					font-size: 14px;
					margin: 0px;
				}
				&:hover {
					.btn {
						background: $roundup-primary;
						color: $white;
						border-color: $roundup-primary;
						cursor: pointer;
					}
				}
				input[type='file'] {
					font-size: 100px;
					position: absolute;
					cursor: pointer;
					left: 0;
					top: 0;
					opacity: 0;
				}
			}
		}
		img {
			max-width: 100%;
			border-radius: 20px;
		}
		.image-cover {
			border-radius: 20px;
			overflow: hidden;
			position: relative;
		}
		.image-logo {
			width: 100px;
			position: absolute;
			left: 40px;
			top: 40px;
			border-radius: 20px;
			overflow: hidden;
		}
	}
	.profile-information {
		form {
			padding-top: 20px;
		}
		.roundup-row {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 40px;
		}
		.flex-row {
			display: flex;
			flexdirection: row;
			alignitems: center;
			justify-content: space-between;
		}
		h3 {
			text-transform: uppercase;
			font-size: 14px;
			margin-bottom: 0px;
			margin-top: 40px;
			color: #71788c;
			letter-spacing: 0;
			color: $roundup-medium-grey;
		}
		p {
			color: $roundup-medium-grey;
			margin-bottom: 0px;
		}
		#autoresizing {
			min-height: 2em;
			display: block;
			overflow: hidden;
			resize: none;
		}
	}
	.modal-actions {
		display: grid;
		grid-auto-column: auto;
		grid-auto-flow: column;
		gap: 10px;
		justify-content: end;
	}
	.section-header {
		font-weight: 600;
		color: #000 !important;
	}
}
</style>
