/* eslint-disable prettier/prettier */
<template>
	<Label class="radio-container" style="padding-bottom: 0px"
		>{{ text }}
		<input
			ref="input"
			type="radio"
			:name="name"
			:value="label"
			v-model="value"
			v-on:click="$emit('input', $event.target.value)"
		/>
		<span class="checkmark"></span>
	</Label>
</template>

<script>
import Label from './Label'

export default {
	name: 'radio-input',
	components: { Label },
	props: ['value', 'label', 'text', 'name'],
	computed: {
		isChecked() {
			return this['v-model'] === this.value
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@roundupapp/component-library/src/styles/index';

.radio-container {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	align-items: flex-start;
	color: $roundup-charcoal;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	&:checked {
		& ~ .checkmark {
			background-color: $roundup-navy;
			&:after {
				display: block;
			}
		}
	}
}
.checkmark {
	position: absolute;
	top: 3px;
	left: 0;
	height: 16px;
	width: 16px;
	background-color: #eee;
	border: 1px solid $roundup-navy;
	border-radius: 50%;
	&:after {
		content: '';
		position: absolute;
		display: none;
		top: 4px;
		left: 4px;
		width: 6px;
		height: 6px;
		border-radius: 3px;
		background: #fff;
	}
}
</style>
