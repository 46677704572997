<template>
	<v-dialog
		v-model="dialog"
		max-width="365px"
		:style="{ zIndex: options.zIndex }"
	>
		<v-card style="padding: 16px !important;">
			<v-card-text
				style="color: #2553CD !important; font-size: 24px; font-weight: 600; padding:0 0 16px 0 !important; text-align: center"
				v-show="!!message"
				>{{ message }}</v-card-text
			>
			<v-card-actions style="padding:0 0 0 0">
				<RoundupButton
					width="160px"
					v-if="!options.noconfirm"
					@click="discardChanges"
					style="background: white !important; color: #DB2137 !important"
					>Discard Changes</RoundupButton
				>
				<RoundupButton width="160px" @click="continueEditing"
					>Continue Editing</RoundupButton
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'roundup-confirm-dialog',
	data() {
		return {
			dialog: false,
			resolve: null,
			reject: null,
			message: 'You have unsaved changes',
			options: {
				color: 'grey lighten-3',
				width: 400,
				zIndex: 200,
				noconfirm: false
			}
		}
	},
	methods: {
		open(message, options) {
			this.dialog = true
			this.message = message
			this.options = Object.assign(this.options, options)

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		continueEditing() {
			this.resolve(true)
			this.dialog = false
		},
		discardChanges() {
			this.resolve(false)
			this.dialog = false
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@roundupapp/component-library/src/styles/index';

.roundup-settings-embed::v-deep .roundup-button-m:hover:enabled::after {
	border-radius: 4px;
}

.roundup-settings-embed::v-deep .roundup-button-m:disabled {
	background: #f5f6f8;
	color: #747a8e !important;
	border: 1px solid #e7e7e8;
}
</style>
