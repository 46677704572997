<template>
	<v-row
		style="
			margin: 16px 0px 16px 0px !important;
			align-items: center;
			justify-content: center;
			width: 100% !important;
		"
	>
		<v-btn-toggle
			:value="value"
			:mandatory="true && !notMandatory"
			@change="v => $emit('change', v)"
			style="width: 100% !important; diplay: flex"
		>
			<v-btn
				v-for="(option, i) in options"
				:key="i"
				:class="[
					'option',
					gutters && 'gutters',
					bigText && 'bigText',
					deep && 'deep',
					disabled && 'disabled'
				]"
				:disabled="disabled"
				:style="{
					border: `1px solid ${
						disabled ? '#747a8d' : '#E3E3E3'
					} !important`
				}"
			>
				{{ buttonText(option) }}
			</v-btn>
		</v-btn-toggle>
	</v-row>
</template>

<script>
export default {
	name: 'option-toggle',
	props: [
		'options',
		'value',
		'gutters',
		'bigText',
		'prefix',
		'notMandatory',
		'deep',
		'disabled'
	],
	methods: {
		buttonText(option) {
			let str = option
			if (this.prefix) str = this.prefix + '' + option
			return str
		}
	}
}
</script>

<style lang="scss" scoped>
.option {
	padding: 1rem;
	text-transform: none !important;
	border: 1px solid $roundup-cerulean !important;
	flex: 1;
	&:first-child {
		border-radius: 4px 0 0 4px !important;
	}
	&:last-child {
		border-radius: 0px 4px 4px 0px !important;
	}
	&.gutters {
		margin-left: 4px !important;
		margin-right: 4px !important;
		border-radius: 4px !important;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2) !important;
		&:first-child {
			margin: inherit !important;
			margin-right: 4px !important;
		}
		&:last-child {
			margin: inherit !important;
			margin-left: 4px !important;
		}
	}
	&.v-btn {
		background: inherit !important;
		color: #2553cd !important;
		height: 40px !important;
		::v-deep .v-btn__content {
			color: #2553cd !important;
			font-weight: 600 !important;
			letter-spacing: normal !important;
			font-family: $font-stack !important;
		}
		.bigText {
			span.v-btn__content {
				font-size: 1.25rem !important;
			}
		}
		&.deep {
			height: 56px !important;
		}
		&.disabled {
			::v-deep .v-btn__content {
				color: $roundup-slate !important;
			}
		}
	}
	&.v-btn--active {
		background: $roundup-primary !important;
		color: white !important;
		border: 1px solid #3770fe !important;
		&::before {
			opacity: 0 !important;
		}
		&.disabled {
			::v-deep .v-btn__content {
				font-weight: 600 !important;
				color: $roundup-slate !important;
			}
		}
		::v-deep .v-btn__content {
			font-weight: 600 !important;
			color: white !important;
		}
	}
	&.bigText::v-deep .v-btn__content {
		font-size: 1.5rem !important;
	}
}

.v-btn {
	@media (max-width: 400px) {
		padding: 0 8px !important;
	}
}
</style>
