<template>
	<div>
		<h1 slot="title">Your Custom Messages</h1>
		<div slot="body">
			<div
				class="roundup-alert roundup-alert-info"
				style="margin-top: 20px"
			>
				Our system will send our various messages when users take
				certain actions, like giving a one time donation or when you
				notify them that a donation failed.
			</div>
			<div :style="{ display: 'flex', justifyContent: 'row' }">
				<form
					v-on:submit.prevent="onUpdateTemplates"
					:style="{ flex: 4 }"
				>
					<div
						class="flex-row"
						:style="{ justifyContent: 'space-between' }"
					>
						<h3>
							Remind Users to Finish Their Account Setup (SMS)
						</h3>
						<ui-button
							:style="{ minWidth: '150px', marginLeft: '15px' }"
							@click="onTestTemplate('reminderTemplate')"
							class="secondary"
							:loading="testLoading"
							>Send Test</ui-button
						>
					</div>
					<ui-label>
						<ui-input
							type="textarea"
							id="autoresizing"
							v-model="reminderTemplate.message"
							placeholder="Remind Users of a task to complete (SMS)"
							:minlength="20"
						/>
					</ui-label>
					<div
						class="flex-row"
						:style="{ justifyContent: 'space-between' }"
					>
						<h3>Thank Users for One Time Gifts (SMS)</h3>
						<ui-button
							:style="{ minWidth: '150px', marginLeft: '15px' }"
							@click="onTestTemplate('thankTemplate')"
							class="secondary"
							:loading="testLoading"
							>Send Test</ui-button
						>
					</div>
					<ui-label>
						<ui-input
							type="textarea"
							id="autoresizing"
							v-model="thankTemplate.message"
							placeholder="Thank Users for One Time Gifts (SMS)"
							:minlength="20"
						/>
					</ui-label>
					<ui-button native-type="submit" :loading="isSaving"
						>Save</ui-button
					>
				</form>
				<tokenList />
			</div>
		</div>
		<modal v-model="test" size="large">
			<form v-on:submit.prevent="onSendSMSTest">
				<p>
					Please be aware that any personalized tokens will be filled
					with your information.
				</p>
				<ui-label>
					<ui-input v-model="testPhone" placeholder="Phone Number" />
				</ui-label>
				<ui-button
					native-type="submit"
					:disabled="testLoading"
					:loading="testLoading"
					>Send Test</ui-button
				>
				<div
					v-if="testMessage"
					class="roundup-alert roundup-alert-info"
					style="margin-top: 20px"
				>
					{{ testMessage }}
				</div>
			</form>
		</modal>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { get, isEmpty, enums } from '@/utilities'
import table from '@/mixins/table'

import UiButton from '../../ui/Button'
import UiLoader from '../../ui/Loader'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import Screen from '../../ui/Screen'
import Modal from '../../ui/Modal'
import TokenList from '../../layout/TokenList'

export default {
	name: 'sms-template-view',
	components: {
		UiButton,
		Screen,
		Modal,
		UiLoader,
		TokenList,
		UiInput,
		UiLabel
	},
	mixins: [table],
	computed: {
		...mapState({
			items: ({ userList }) => userList.items,
			total: ({ userList }) => userList.total,
			institution: ({ institution }) => institution.item,
			isLoading: ({ notify }) => notify.isFetching,
			me: ({ me }) => me.current
		})
	},
	data() {
		return {
			reminderTemplate: {
				template_id: enums.templates.smsReminder,
				message:
					`Hi {{first_name}}, thank you so much for giving to us via RoundUp App. It looks you haven't finished setup. Please do so... every roundup helps  us ${this.$store.state.institution.item.full_impact_statement}. Click the link to finish https://app.roundupapp.com/#/login -` +
					this.$store.state.institution.item.name
			},
			thankTemplate: {
				template_id: enums.templates.smsThank,
				message:
					`Thank you so much for your gift to us. Each donation helps us ${this.$store.state.institution.item.full_impact_statement} -` +
					this.$store.state.institution.item.name
			},
			test: false,
			testTemplate: null,
			testPhone: null,
			testLoading: false,
			testMessage: null,
			isSaving: false
		}
	},
	methods: {
		...mapActions(['getTemplates', 'updateTemplates', 'sendSMS']),
		onUpdateTemplates() {
			this.isSaving = true
			const templates = [this.thankTemplate, this.reminderTemplate]
			this.updateTemplates(templates)
				.then(() => {
					this.isSaving = false
					this.$swal(
						'Success',
						'Your templates has successfully been updated.'
					)
				})
				.catch(({ response }) => {
					this.isSaving = false
					this.$swal('Error', response.data.message)
				})
		},
		onSendSMSTest() {
			this.testLoading = true
			window.analytics.track('send_sms_test')
			this.sendSMS({
				userId: this.me.id,
				message: this[this.testTemplate].message,
				phone: this.testPhone.replace('[^0-9]', ''),
				test: false
			})
				.then(mid => {
					this.testLoading = false
					this.testPhone = null
					this.testMessage = 'Your message has been sent'
				})
				.catch(({ response }) => {
					this.testMessage = 'Error: ' + response.data.message
					this.testLoading = false
					this.$swal('Error', response.data.message)
				})
		},
		onTestTemplate(template) {
			this.test = true
			this.testMessage = null
			this.testTemplate = template
		},
		get,
		isEmpty
	},
	mounted() {
		this.getTemplates().then(list => {
			const rmTemp = list.filter(
				t => t.template_id === enums.templates.smsReminder
			)[0]
			if (rmTemp) {
				this.reminderTemplate = cloneDeep(rmTemp)
			}
			const thankTemp = list.filter(
				t => t.template_id === enums.templates.smsThank
			)[0]
			if (thankTemp) {
				this.thankTemplate = cloneDeep(thankTemp)
			}
		})
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
</style>
